<template>
    <div :class="'Page page-' + context ">
        <div :id="'pagetop-' + context" class="pagetop">
            <div :id="'pagelead-' + context" class="pagelead">
                <AppBanner />
            </div>
            <div v-if="blocks.first" class="firstblock fullwrapper" :id="blocks.first._id" :key="blocks.first._id">
                <v-container class="pt-6 contentwrapper"  >
                    <ContentBlock :record="blocks.first" :options="childOptions(blocks.first)" v-on:actionhandler="handleEditorActions" />
                </v-container>
            </div>
        </div>
        <div v-if="isLoading" id="isLoading" class="isLoading">
            <v-container  v-html="loc('Loading...')">
            </v-container>
        </div>
        <div v-else id="pagecontent">
            <v-row v-for="item in blocks.list" class='fullwrapper' :id="item._id" :key="item._id">
                <v-container class="pt-6 contentwrapper" ><pre style="display:none">{{ item }}</pre>
                    <CoursesListing v-if="item.subtype==='courselisting'" :record="item" :options="{preview: preview}" />
                    <ContentBlockCards v-else-if="item.subtype==='cards'" :record="item" :options="{preview: preview, isEditing: (isEditing === item._id && item) }" />
                    <ContentBlockAccordians v-else-if="item.subtype==='accordian'" :record="item" :options="{preview: preview, isEditing: (isEditing === item._id && item) }" />
                    <UserRegister v-else-if="item.subtype==='register'" :record="item" :after="item.after" :options="{embed: true}" v-on:actionhandler="handleEditorActions" />
                    <ContentBlockSurvey v-else-if="item.subtype==='survey'" :record="item" context="Page" :contextid="context" :sid="item.body" :options="{}" v-on:actionhandler="handleEditorActions" />
                    <AppLanguageList v-else-if="item.subtype==='languagelist'" :record="item" :options="{preview: preview}" />
                    <ContentBlock v-else :record="item" :options="childOptions(item)" v-on:actionhandler="handleEditorActions" />
                </v-container>
            </v-row>
            <v-container v-if="isEditor && records && !isEditing"  class='EditorActionBar'>
                <v-row class='pa-2'>
                    <v-btn @click="handleAdd" color="primary">{{ loc('Add Another Block To This Page') }}</v-btn>
                </v-row>
                <v-row  class='pa-2'>
                    <v-btn @click="handleEditProps" color="primary">{{ loc('Edit Page Properties') }}</v-btn>
                </v-row>
                <v-row  class='pa-2'>
                    <v-btn @click="togglePreview" color="info" >{{ loc('Preview') }}</v-btn>
                </v-row>
            </v-container>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import ContentBlock from './Content_Block';
import CoursesListing from './Courses_Listing';
import ContentBlockCards from './Content_Block_Cards';
import ContentBlockSurvey from './Content_Block_Survey';
import ContentBlockAccordians from './Content_Block_Accordians.vue';
import AppBanner from './App_Banner';
import AppLanguageList from './App_Language_List.vue';
import UserRegister from './User_Register';
import _sortBy from 'lodash/sortBy';
import _remove from 'lodash/remove';
// import siteconfig from '../config/site.js';

export default {
    name: 'Page',
    metaInfo () {
        let meta = {};
        let info = this.pageInfo;
        let siteconfig = this.siteconfig;
        meta.title = info.title;
        if(siteconfig && siteconfig.sitetitle) meta.titleTemplate = siteconfig.sitetitle + ' - %s';
        if(info.style) meta.style = [{ cssText: info.style }];
        if(info.description) meta.meta = [{vmid: 'description', name: 'description', content: info.description}];
        // if(info.scripthead) meta.script = info.scripthead;
        console.log('meta', meta);
        return meta;
    },
    data () {
        return {
            context: false,
            records: false,
            isEditing: false,
            isAdding: false,
            isLoading: true,
            preview: false
        }
    },
    components: { 
        ContentBlock, CoursesListing, ContentBlockCards, ContentBlockAccordians, ContentBlockSurvey, AppBanner, UserRegister, AppLanguageList
    },
    computed: {
        ...mapState(['siteconfig', 'users', 'content', 'localize']),
        lang() {
            return this.users.lang;
        },
        isEditor() {
            return !this.preview && this.users.currentUser && this.users.currentUser.isEditor;
        },
        pageInfo() {
            return (this.records && this.records[this.context]) || (this.records && this.records.pagenotfound) || {}
        },
        blocks() {
            // creates an array of elements, then removes the page 'element' (which is meta properties, not content blocks)
            if(!this.records) return [];
            let b = Object.values(this.records);
            let lang = (this.users && this.users.lang) || 'en';
            _remove(b, (r) => { return lang === 'en' ? (r.lang !== 'en' || !r.lang) : r.lang !== lang });
            _remove(b, (r) => { return r._id === this.context } ) 
            b = _sortBy(b, ['order', '_id']);
            console.log("blocks", this.records, b);
            let out = {
                first: b[0],
                list: b.slice(1)
            };
            // out.list.splice(1,0, {type: 'courselisting'});
            // out.first = b.slice(0,1)
            // out.list = b.slice(1);
            return out;

            // let first = b.shift();
            // return {list: b, first: first};
        }
    },
    methods: {
        loadPage: function(context){
            console.log('loadPage')
            if(!context) context = this.context; 
            this.isLoading = true;
            this.$store.dispatch('content/loadPage', {context: context, subid: this.$route.params.subid})
                .then((result) => {
                    this.isLoading = false;
                    this.records = result;
                    console.log("loaded", this.isEditor, Object.keys(result).length < 1);
                    if(this.isEditor && Object.keys(result).length < 1){
                        // empty page, likely just adding it, so open the editor.
                        this.handleAdd()
                    } else if(this.$route.hash){
                        let hash = this.$route.hash.slice(1);
                        let el = document.getElementById(hash)
                        if(el){ 
                            el.scrollIntoView({ behavior: 'smooth' });
                        }
                    }

                    if(result && result[this.context] && result[this.context].scripthead && this.users.cookieoptin && this.users.cookieoptin.setting === 'optin'){
                        let contents = result[this.context].scripthead;
                        let scripthead = document.getElementById('scripthead');
                        // console.log("Adding scripthead", scripthead, contents);
                        if(!scripthead){
                            let snode = document.createTextNode(contents);
                            let sel = document.createElement('script');
                            sel.id = 'scripthead';
                            sel.appendChild(snode);
                            document.head.appendChild(sel);
                        }
                    } else {
                        // console.log("Can't find a scripthead", this.context, result)
                    }
                    if(result && result[this.context] && result[this.context].scriptfoot && this.users.cookieoptin && this.users.cookieoptin.setting === 'optin'){
                        let contents = result[this.context].scriptfoot;
                        let scriptfoot = document.getElementById('scriptfoot');
                        // console.log("Adding scriptfoot", scriptfoot, contents);
                        if(!scriptfoot){
                            let snode = document.createTextNode(contents);
                            let sel = document.createElement('script');
                            sel.id = 'scriptfoot';
                            sel.appendChild(snode);
                            document.body.appendChild(sel);
                        }
                    } else {
                        // console.log("Can't find a scriptfoot", this.context, result)
                    }                    
                    console.log('Page.loadPage result', result);
                })
        },
        handleAdd: function () {

            let timestamp = new Date();
            let newkey = this.context + timestamp.valueOf().toString(36);
            let record = {
                _id: newkey,
                type: 'block',
                context: this.context,
                body: 'New Block',
                lang: this.users.lang || this.siteconfig.defaultlang || 'en',
                timestamp: timestamp.toISOString(),
                order: this.blocks.length + 1
            };
            if(record[this.context] && record[this.context].lang) record[newkey].lang = record[this.context].lang;
            // this.records[newkey]
            console.log("Adding block record", record, this.blocks);
            this.$set(this.records, newkey, record);
            this.isAdding = true;
            this.isEditing = newkey;
        },
        handleEditProps: function () {
            let goto = "/admin/content/" + this.context;
            this.$router.push(goto);
        },
        handleEditorActions: function (evt, opts){
            console.log("handleEditorActions", evt, opts);
            if(!evt || !evt.action) return;

            switch(evt.action){
                case 'toggleedit':
                    this.isEditing = evt.editing;
                    // if(this.isAdding) this.isAdding = false;
                    break;
                case 'remove':
                    this.isEditing = false;
                    this.loadPage();
            }
        },
        togglePreview: function() {
            this.preview = !this.preview;
        },
        childOptions(item) {
            // console.log('childOptions:', this.isEditing, item._id, (this.isEditing === item._id))
            return {
                isEditing: !this.preview && (this.isEditing === item._id),
                preview: this.preview
            }
        },
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },             
    },
    created () {
        console.log(this.$route)
        this.context = this.$route.params.id || 'home';
        this.loadPage();
    },
    destroyed (){
        let hel = document.getElementById('scripthead');
        if(hel){ 
            hel.remove();
            console.log('removed scripthead', hel);
        }
        let fel = document.getElementById('scriptfoot');
        if(fel){ 
            fel.remove();
            console.log('removed scriptfoot', fel);
        }
    }, 
    watch: {
        '$route.params.id': {
            handler: function(s){
                console.log("watcher triggered", s);
                this.context = s || 'home';
                this.loadPage();
            },
            deep: true,
            immediate: false
        },
        'lang' (newLang, oldLang){
            console.log('lang change', newLang, oldLang);
            this.loadPage();
        },
        'pageInfo' (v2, v1){
            console.log('pageInfo change', v2, v1, v2.scripthead, v2.scriptfoot)
        } 
    }
};
</script>
