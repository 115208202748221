import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import store from './store';

import siteConfigLocal from '../config/site.js';
import { filterDate, filterId } from './filter';
import _size from 'lodash/size';

const axiosconfig = { headers: {} };
var asof = 1;

export default {
    namespaced: true,
    state: {
        config: false,
        cookieoptin: false,
        cookiesnip: false,
        currentUser: false,
        lang: 'en',
        loggedout: false,
        list: false,
        instructors: false
    },
    mutations: {
        setCurrentUser(state, val) {
            if (val && val._id) {
                if (val.roles) {
                    if (val.roles.cms || val.roles.admin) val.isEditor = true;
                    if (val.roles.admin) val.isAdmin = true;
                }
                state.currentUser = val;
                state.loggedout = false;
                if(val.lang) state.lang = val.lang;
            }
        },
        setCurrentUserField(state, val) {
            if (!state.currentUser) return false;
            if (val && val.field){ 
                Vue.set(state.currentUser, val.field, val.value);
                if(state.list && state.list[state.currentUser._id]){
                    Vue.set(state.list[state.currentUser._id], val.field, val.value);
                }
                if(val.field === 'lang') state.lang = val.value;
            }
            
        },
        removeCurrentUser(state, val) {
            state.currentUser = false;
            state.loggedout = true;
        },
        setList(state, val) {
            state.list = val;
        },
        setUser(state, val) {
            if (val && val._id) {
                state.list = state.list || {};
                Vue.set(state.list, val._id, Object.assign({}, (state.list[val._id] || {}), val));
                if(_size(val.roles)){
                    for(let k in val.roles) Vue.set(state.list[val._id], k, val.roles[k]);
                }
                if(_size(val.certifications)){
                    for(let k in val.certifications) Vue.set(state.list[val._id], k, val.certifications[k]);
                }

            } else {
                console.log("mutating user with no user", val);
            }

        },
        removeUser(state, val) {
            console.log("removeUser", val)
            if (typeof val === "string" && state.list[val]) {
                Vue.delete(state.list, val);
            } else if (val && val._id) {
                Vue.delete(state.list, val._id);
            }
        },
        setInstructors(state, val) {
            console.log("setInstructors",val);
            state.instructors = state.instructors || {};

            for(let k in val){
                Vue.set(state.instructors, k, val[k] );
            }
        },
        setLang(state, val) {
            state.lang = val;
        },
        setCookieOptin(state, val) {
            state.cookieoptin = val;
        }

    },
    actions: {
        clearAll({ commit, state }) {
            console.log("users clearAll");
            commit('setList', false);
            commit('removeCurrentUser');
            return Promise.resolve();
        },
        whoami({ commit, state, dispatch }, data, rootState) {
            return axios.get("/app_users/whoami?asof="+asof, axiosconfig)
                .then((data) => {
                    
                    let result = data && data.data;
                    // console.log('whoami?', result);
                    if(result.asof) asof = result.asof;
                    if(result && result.cookieoptin){
                        // console.log('whoami cookieoptin', result.cookieoptin, state.cookieoptin)
                        if(result.cookieoptin !== undefined && (!state.cookieoptin || result.cookieoptin !== 'prompt')){ 
                            localStorage.setItem('user_cookie_optin', JSON.stringify(result.cookieoptin)); 
                            commit('setCookieOptin', result.cookieoptin);
                        }
                    }
                    if (result && result.user) {
                        let user = result.user;
                        // if(result.config){
                        //     let config = {...result.config};
                        //     if(result.loc){
                        //         let lang = {};
                        //         lang[result.lang || 'en'] = result.config.loc;
                        //         dispatch('localize/switchLanguage',lang, {root: true});
                        //         delete result.config.loc;
                        //     }
                        // }

                        commit('setCurrentUser', user);
                        
                        if (user.roles && (user.roles.cms || user.roles.admin)) dispatch('setNavAdmin', null, { root: true });
                    } else if (data.status === 200) {
                        commit('removeCurrentUser');
                    }
                    return result;
                })
                .catch((e) => {
                    console.log('error', e);
                })
        },
        login({ commit, state, dispatch }, data, rootState) {
            let ndata = {
                login: data.username || data.email,
                password: data.password,
                cors: true,
                format: "json"
            }

            console.log("sending login", ndata.login)
            // special handling on server at /users/login.
            return axios.post("/users/login", ndata, axiosconfig)
                .then((response) => {
                    // console.log('login?', response && ((response.data || response)));
                    console.log('login?', response);
                    let result = response && response.data;
                    if (result) {
                        console.log("setting current user", result);
                        commit('setCurrentUser', result);
                        if(result.lang) commit('setLang', result.lang);
                        if (result.roles && (result.roles.cms || result.roles.admin)) dispatch('setNavAdmin', null, { root: true });
                        if(result && result.classes){
                            let enroll = {};
                            enroll[result._id] = result.classes;
                            store.dispatch('enrollment/set', enroll, {root: true});
                        }                        
                        return result;
                    }
                    return response;
                })
                .catch((e) => {
                    console.log('error logging in', e.response);
                    return e.response;
                });
        },
        logout({ commit, state }, data, rootState) {
            return axios.post("/app_users/logout", axiosconfig)
                .then((data) => {
                    // This may be unnecessary, because the server redirects on logout, but the 
                    // redirect might only impact axios, which doesn't reset vue.
                    commit('removeCurrentUser', result);
                    let status = data.status;
                    let result = data && data.data;
                    if (status === 200) {
                        window.location.href = '/';
                    }
                })
                .catch((e) => {
                    console.log('error logging out', e.response);
                    return e.response;
                });
        },
        register({ commit, state }, data) {
            return axios.post("/app_users/register", data, axiosconfig)
                .then((response) => {
                    console.log('registration posted', response);
                    let result = response && response.data;
                    if (result) {
                        if (!result.info && !result.error) {
                            console.log("setting current user", result);
                            commit('setCurrentUser', result);
                        }
                        return result;
                    }
                    return response;
                })
                .catch((e) => {
                    console.log('error posting registration', e);
                })
        },
        passwordReset({ commit, state }, data) {
            return axios.post("/app_users/resetpassword", data, axiosconfig)
                .then((response) => {
                    console.log('reset password posted', response);
                    let result = response && response.data;
                    if (result) {
                        return result;
                    }
                    return response;
                })
                .catch((e) => {
                    console.log('error posting password reset', e);
                })
        },
        get({ state, dispatch }, data) {
            if(!data || !data._id) return Promise.reject()
            if(state.list && state.list[data._id]) return Promise.resolve(state.list[data._id]);
            if(state.currentUser && data._id === state.currentUser._id) return Promise.resolve(state.currentUser);

            return dispatch('getList')
                .then((result) => {
                    return result && result[data._id];
                })
        },
        getList({ commit, state }, data) {
            console.log("users.getList", data);
            return axios.post("/app_users/list", data, axiosconfig)
                .then((response) => {
                    let result = response && response.data;
                    if (result) {
                        // let listlength = _size(result);
                        for (let i in result) {
                            if (!result[i].created) result[i].created = result[i].timestamp ? filterDate(result[i].timestamp, 'YYYY-MM-DD') : "";
                            if (!result[i].lastseen) result[i].lastseen = result[i].lasttime ? filterDate(result[i].lasttime, 'YYYY-MM-DD HH:mm:ss') : "";
                            // if (!result[i].roles) result[i].roles = {};
                            // out[result[i]._id] = result[i];
                        }
                        console.log('getList committing result', result);
                        commit('setList', result);
                    }
                    return result;
                })
                .catch((e) => {
                    console.log('error getting user list', e)
                })
        },
        save({ commit, state }, data) {
            if (!data._id) return Promise.reject("You had one job...");
            console.log("entering users.save", data)

            let path = '/app_users/save';
            return axios.post(path, data, axiosconfig)
                .then((result) => {
                    console.log('Save result 1', result);
                    if (result && result.data ) {
                        let record = result.data.value || result.data;
                        console.log("Save result", record);
                        if (!record.created && record.timestamp) record.created = filterDate(record.timestamp, 'YYYY-MM-DD');
                        if (!record.lastseen && record.lasttime) record.lastseen = filterDate(record.lasttime, 'YYYY-MM-DD');
                        commit('setUser', record);
                        if (record.cookieoptin !== undefined){
                            localStorage.setItem('user_cookie_optin', JSON.stringify(record.cookieoptin));
                            commit('setCookieOptin', record.cookieoptin);                            
                        }
                        if(state.currentUser && record._id === state.currentUser._id){
                            for(let k in record){
                                if(k === '_id') continue;
                                commit('setCurrentUserField', {field: k, value: record[k]});
                            }
                        }
                        return record;
                    } 
                })
                .catch((error) => {
                    console.log('error in users_save', error, error.response);
                    return error.response;
                })
        },
        remove: function ({ commit, state }, data) {
            console.log("users.remove", data);

            data = filterId(data);

            if (!data || typeof data !== "string") return Promise.reject("I cannot remove nothing.");

            commit('removeUser', data);

            let path = "/app_users/remove/" + data;

            return axios.get(path, axiosconfig)
                .then((result) => {
                    console.log('users.remove returning', result.data);
                    return result && result.data;
                })
                .catch((error) => {
                    console.log('error in users_remove', error.response);
                    return error && error.response;
                })
        },
        getInstructors: function ({ commit, state }, data) {
            console.log("users.getInstructors", data);

            data = data || {};

            if(data.course && state.instructors && state.instructors[data.course]) return Promise.resolve(state.instructors[data.course]);

            return axios.post('/app_users/instructors', data, axiosconfig)
                .then((response) => {
                    let result = response && response.data;
                    commit('setInstructors', result);
                    return result;
                })
                .catch((error) => {
                    console.log("ERROR getting instructors list", error.response);
                    return error && error.response;
                })
        },
        setCookieOptin: function({ commit, state }, data){
            if(data === undefined){
                data = JSON.parse(localStorage.getItem('user_cookie_optin'))
                if(data) commit('setCookieOptin', data);
                return Promise.resolve();
            } else if(data === 'optin' || data === 'optout' || data === 'prompt'){
                return axios.post("/app_users/cookieoptin", {cookieoptin: data}, axiosconfig)
                    .then((response) => {
                        let result = response && response.data;
                        localStorage.setItem('user_cookie_optin', JSON.stringify(data));
                        commit('setCookieOptin', data);
                        return result;
                    })
            } else {
                localStorage.setItem('user_cookie_optin', JSON.stringify(data));
                commit('setCookieOptin', data);
                return Promise.resolve();
            }
        },
        getCookieSnippet: function(){
            return; 
            //  axios.post("/app_clientconfig/thirdpartyconfig")
            //     .then((response) => {
            //         let result = response && response.data;
            //         console.log('SNIP', typeof result, result);
            //     })
        },
        setLanguage: function({ commit, state }, data){
            if(data){
                commit('setCurrentUserField', 'lang', data);
                commit('setLang', data);
            }
        },
        switchLanguage: function ({ commit, state }, data) {
            if(!data || typeof data !== "string" || data !== filterId(data)) return Promise.reject("Invalid language.");
            if(siteConfigLocal && siteConfigLocal.langredirects && siteConfigLocal.langredirects[data]){ 
                window.location.href = siteConfigLocal.langredirects[data];
                return;
            }
            return axios.post("/app_users/setlang/", {lang: data})
                .then((response) => {
                    let result = response && response.data;
                    console.log('store_users.switchLangauge result', result);
                    if(result && result.lang){
                        commit('setCurrentUserField', 'lang', result.lang);
                        commit('setLang', result.lang);
                // if(result){ // from root store whoami call.
                        if(result.config) store.dispatch('setConfig', result.config, {root: true});
                        if(result.loc) store.dispatch('localize/setLanguage', result.loc, {root: true});
                        if(result.user && result.user.classes){
                            store.dispatch('enrollment/set', result.user, {root: true});
                        }
                        
                // }                        
                    }
                    return result;
                })
        },
        export: function({ state }, data) {
            return axios.get("/app_reports/users/users.csv");
        }
    },
    getters: {
        currentUser: state => state.currentUser
        // checkIds: state => (state.checks && Object.keys(state.checks)) || []
    }
}

